<template>
  <div class="pie_Wrap">
    <h2>
      <i>监测平台数据总量</i>
      <i>{{chartData.total}}<span>条</span></i>
    </h2>
    <div ref="myChart" :style="{'width':width,'height':height,'margin':'0 auto'}"></div>  
  </div>  
</template>

<script>
export default {
  name:'PieChart',
  props:{
    width:{
      type:String,
      default:'90%'
    },
    height:{
      type:String,
      default:'180px'
    },
    chartData:Object,   // 数据来源
  },
  data() {
    return {
      myChart:''
    }   
  },
  watch:{
    chartData:{
      handler(newValue){
        this.chartData=newValue
        this.initChart()
      },
      deep: true
    }
  },
  methods:{
    initChart() {  // 画图表
      this.myChart = this.$echarts.init(this.$refs.myChart)  // 基于准备好的dom，初始化echarts实例
      this.myChart.clear()
      // 绘制条形图
      var options={
          color:['#ffaf25', '#215973'],
          series: [
            {
              type:'pie',
              center: ['25%', '50%'],  // 饼图的中心（圆心）坐标
              radius: ['50%', '60%'],  // 饼图的半径
              avoidLabelOverlap: false, // 防止标签间的重叠
              label: {  // 饼图图形上的文本标签
                show: true,
                position: 'center'
              },
              data: [
                {
                  value: this.chartData.areaData.areas_count,
                  name: this.chartData.areaData.name,
                  itemStyle: {color: '#25ff8c',shadowColor: 'rgba(37, 255, 140, 1)',shadowBlur: 20},
                  label:{
                    formatter: ['{a|'+ this.$utils.filterStr(this.chartData.areaData.name)+'}','{b|'+this.chartData.areaData.areas_count+'}'].join('\n'),
                    rich: {
                      a: { color: '#fff',fontSize: '14',lineHeight:'34'},
                      b: {color: '#25ff8c',fontSize: '14'}
                    }
                  }                
                },
                {value:this.chartData.areaData.areas_count, name:'',itemStyle: { color: '#215973' } }
              ]
            },
            {
              type:'pie',
              center: ['75%', '50%'],  // 饼图的中心（圆心）坐标
              radius: ['50%', '60%'],  // 饼图的半径
              avoidLabelOverlap: false, // 防止标签间的重叠
              label: {  // 饼图图形上的文本标签
                show: true,
                position: 'center'
              },
              data: [
                {
                  value: this.chartData.currentData.cat_count,
                  name: this.chartData.currentData.name,
                  itemStyle: {color: '#ffaf25',shadowColor: 'rgba(255, 175, 37, 1)',shadowBlur: 20},
                  label:{
                    formatter: ['{a|'+this.chartData.currentData.name+'}','{b|'+this.chartData.currentData.cat_count+'}'].join('\n'),
                    rich: {
                      a: { color: '#fff',fontSize: '14',lineHeight:'34'},
                      b: {color: '#FFAF25',fontSize: '14'}
                    }
                  }                
                },
                {value:this.chartData.currentData.cat_count, name:'',itemStyle: { color: '#215973' } }
              ]
            }
          ]
      }
      this.myChart.setOption(options) 
    }
  }
};
</script>

<style lang="less" scoped>
  .pie_Wrap{ width: 370px;height: 246px; background: url('../../assets/img/homeRightBg.png') no-repeat; background-size: 100% 100%;
    h2{display: flex; align-items: center; justify-content: space-between;  padding:20px 20px 0;font-size: 16px;
      i:first-child{color: #fff; font-weight: bold;
        &:before{content: ''; display: inline-block;width: 8px;height: 8px;border: 2px solid #55fdfd;border-radius: 50%;margin-right: 4px;}
      }
      i:last-child{color: #fff;color: #55d7fd; font-size: 22px;font-family: "fontb" !important;font-weight: normal;
        span{ font-size: 14px;margin-left: 3px;}
      }
    }
  }  
</style>
