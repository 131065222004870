<template>
  <div class="home_wrap">
    <div class="header">
      <div class="title">
        <img src="../assets/img/indexLogo.png" />
        <h1 v-if="type === 'laowo'">
          南亚东南亚建设工程材料及设备价格波动监测
        </h1>
        <h1 v-else>南亚东南亚建设工程材料及设备价格波动监测</h1>
        <el-dropdown class="select-wrapper">
          <span class="el-dropdown-link">
            {{ country }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <router-link to="/" tag="a">
              <el-dropdown-item
                style=" font-size: 20px ;
          color: #55c0fd ;
          "
                >首页</el-dropdown-item
              ></router-link
            >
            <el-dropdown-item
              ><a
                href="http://index.ynbzde.com/#/"
                style=" font-size: 20px ;
          color: #55c0fd ;
          "
                >中国·云南</a
              ></el-dropdown-item
            >
            <router-link to="/home" tag="a">
              <el-dropdown-item
                style=" font-size: 20px ;
          color: #55c0fd ;
          "
                >缅甸</el-dropdown-item
              ></router-link
            >
            <router-link to="/home?type=laowo" tag="a">
              <el-dropdown-item
                style=" font-size: 20px ;
          color: #55c0fd ;
          "
              >
                老挝</el-dropdown-item
              ></router-link
            >
            <el-dropdown-item
              disabled
              style=" font-size: 20px ;
          "
            >
              柬埔寨</el-dropdown-item
            >
            <el-dropdown-item
              disabled
              style=" font-size: 20px ;
          "
              >越南</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="link" @click="jump">进入工作台 ENTER</div>
    </div>
    <div class="body">
      <div class="left">
        <div class="count">
          <dl>
            <dt>监测省级</dt>
            <dd>{{ provinceCount }}</dd>
          </dl>
          <!-- <dl>
            <dt>监测区、县级</dt>
            <dd>000</dd>
                    </dl>-->
        </div>
        <p class="display_title">材料价格指数及环比涨跌</p>
        <p class="display_time">时间：{{ listTime }}</p>
        <ul class="display_list">
          <li v-for="(item, index) in listData" :key="index">
            <h2>
              <i>{{ item.name }}</i>
              <i>{{ item.data.base_index }}</i>
            </h2>
            <el-progress
              :percentage="percentageFn(item.data.chain_rate)"
              :color="colorFn(item.data.chain_rate)"
              :show-text="false"
              :stroke-width="4"
            ></el-progress>
            <h3>
              <i>{{ item.data.chain_rate }}</i>
              <img
                v-if="item.data.chain_rate > 0"
                src="../assets/img/homeUp.png"
              />
              <img
                v-else-if="item.data.chain_rate < 0"
                src="../assets/img/homeDown.png"
              />
              <i v-else>-</i>
            </h3>
          </li>
        </ul>
      </div>
      <div class="center">
        <div class="map">
          <div class="map_title">
            <p>当前展示</p>
            <p>{{ this.currentData.name }}</p>
            <img src="../assets/img/upDowmTips.png" alt />
          </div>
          <MapChart
            :chartData="currentData"
            @handleClickMap="handleClickMap"
            @resetMap="getData"
            :are="type"
          />
        </div>
      </div>
      <div class="right">
        <PieChart :chartData="pieData" style="margin-bottom:20px" />
        <LineChart
          :nationid="nationid"
          :index="index"
          style="margin-bottom:20px"
        />
        <BarChart :chartData="currentData" :areaName="areaName" />
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "../components/Home/PieChart";
import BarChart from "../components/Home/BarChart";
import LineChart from "../components/Home/LineChart";
import MapChart from "../components/Home/MapChart";
export default {
  name: "Home",
  components: {
    PieChart,
    BarChart,
    LineChart,
    MapChart,
  },
  data() {
    return {
      nationid: 2, // 选择的国家
      type: "miandian", // 数据地区
      provinceCount: 0,
      listTime: "",
      areaName: "",
      listData: [],
      currentData: {},
      pieData: {},
      timerChart: "",
      index: 0,
    };
  },
  created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
      switch (this.type) {
        case "laowo":
          this.nationid = 3;
          break;
        default:
          this.nationid = 2;
      }
    }
    if (this.type == "laowo") {
      sessionStorage.setItem("nationid", 3);
    } else {
      sessionStorage.setItem("nationid", 2);
    }
    this.getData();
  },
  computed: {
    country() {
      return this.$route.query.type === "laowo" ? "老挝" : "缅甸";
    },
  },
  watch: {
    $route() {
      this.$router.go(0);
    },
  },
  methods: {
    jump() {
      // 跳转之前写入默认的国家
      this.$store.commit("setNationid", this.nationid);
      this.$router.push("/login");
    },
    percentageFn(num) {
      return isNaN(num) ? 0 : Math.abs(num);
    },
    colorFn(num) {
      if (num > 0) return "#FD3A0D";
      else if (num < 0) return "#0DFDA0";
      else return "";
    },
    async handleClickMap(id, cb) {
      try {
        await this.getData(id);
        this.index = 0; // 索引重置
        cb && cb();
      } catch (err) {
        // err
        this.animateData();
      }
    },
    animateData() {
      let index = 0;
      this.timerChart = setInterval(() => {
        ++index;
        if (index > this.listData.length - 1) index = 0;
        this.listData.push(this.listData[0]);
        this.listData.shift();
        this.currentData = this.listData[0];
        this.pieData.currentData = this.listData[0];
        this.index = index;
      }, 6000);
    },
    async getData(areas = 0) {
      clearInterval(this.timerChart); // 清除数据动画
      this.index = 0; // 索引重置
      let {
        all_count,
        areas_count,
        name,
        province_count,
        data,
        terms_name,
      } = await this.$reqApi.getDataIndex({
        nationid: this.nationid,
        areas,
      });
      this.provinceCount = province_count; //监测省数量
      this.listData = data;
      this.listTime = terms_name;
      this.areaName = name;
      this.currentData = data[0];
      this.pieData = {
        total: all_count,
        areaData: { areas_count, name },
        currentData: this.currentData,
      };
      this.animateData();
    },
  },
};
</script>

<style lang="less" scoped>
.home_wrap {
  width: 100%;
  height: 100%;
  min-width: 1360px;
  min-height: 768px;
  background: #010017 url("../assets/img/indexbg.jpg") no-repeat;
  background-size: 100% 100%;
  overflow: auto;
  position: relative;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    background: url("../assets/img/indexTiltleBg.png") no-repeat center bottom;
    background-size: 100%;
    .title {
      display: flex;
      align-items: center;
      margin-left: 20px;
      img {
        width: 45px;
        flex-shrink: 0;
        margin-right: 10px;
      }
      h1 {
        color: #fff;
        font-size: 32px;
        font-weight: normal;
      }
      .select-wrapper {
        background: none;
        margin-left: 60px;
        .el-dropdown-link {
          font-size: 20px;
          color: #55c0fd;
          cursor: pointer;
        }
        /deep/.el-button {
          font-size: 20px;
          color: #55c0fd;
        }
        .item {
          cursor: pointer;
          font-size: 20px;
          color: #55c0fd;
          text-shadow: 2px 0 10px #55c0fd, -2px 0 10px #55c0fd;
        }
      }
    }

    .link {
      cursor: pointer;
      font-size: 20px;
      color: #55c0fd;
      text-shadow: 2px 0 10px #55c0fd, -2px 0 10px #55c0fd;
      margin-right: 50px;
    }
  }
  .body {
    margin-top: 30px;
    .left {
      float: left;
      width: 360px;
      height: 700px;
      position: relative;
      z-index: 1;
      font-family: MicrosoftYaHei-Bold;
      font-weight: bold;
      .count {
        font-family: MicrosoftYaHei-Bold;
        color: #55fdfd;
        margin-bottom: 15px;
        display: flex;
        text-align: center;
        dl {
          flex: 1;
          dt {
            font-weight: bold;
            margin-bottom: 10px;
          }
          dd {
            font-size: 60px;
            text-shadow: 0px 0px 10px rgba(85, 227, 253, 0.4);
            font-family: "fontb";
            font-style: normal;
            line-height: 1;
            display: flex;
            justify-content: center;
            &:before,
            &:after {
              content: "";
              height: 58px;
              width: 7px;
            }
            &:before {
              background-image: url("../assets/img/homeCountBgL.png");
            }
            &:after {
              background-image: url("../assets/img/homeCountBgR.png");
            }
          }
        }
      }
      .display_title {
        color: #55fdfd;
        margin: 30px 0 15px;
        text-align: center;
      }
      .display_time {
        color: #0dfda0;
        margin-bottom: 15px;
        font-size: 12px;
        text-align: center;
      }
      .display_list {
        height: 610px;
        overflow: hidden;
        li {
          min-height: 92px;
          padding: 15px 40px;
          &:first-child {
            background: url("../assets/img/homeLeftTitleBg.png") no-repeat 0 0;
            background-size: 100% 92px;
          }
          h2 {
            display: flex;
            justify-content: space-between;
            color: #fff;
            font-size: 15px;
            i:first-child {
              flex: 1;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            i:last-child {
              flex-shrink: 0;
            }
          }
          /deep/ .el-progress {
            margin: 10px 0;
          }
          h3 {
            color: #95979c;
            font-size: 14px;
            display: flex;
            align-items: center;
            i:first-child {
              width: 60%;
            }
            img {
              width: 10px;
              height: 10px;
            }
          }
        }
      }
    }
    .center {
      float: left;
      padding: 0 400px 0;
      width: 100%;
      box-sizing: border-box;
      margin-left: -400px;
      .map {
        margin: 0 10px;
        font-size: 20px;
        font-family: MicrosoftYaHei-Bold;
        font-weight: bold;
        color: #55fdfd;
        .map_title {
          text-align: center;
          p:first-child {
            font-size: 16px;
          }
          p:nth-child(2) {
            background: url("../assets/img/homeMapTitleBg.png") no-repeat center
              center;
            min-height: 53px;
            line-height: 53px;
            margin: 16px 0;
          }
        }
      }
    }
    .right {
      float: left;
      margin-left: -400px;
      position: relative;
      z-index: 1;
      padding-right: 30px;
    }
  }
}
</style>
