import mianDian from './json/mianDian.json'
import mgs from './json/mgs.json'
import rkb from './json/rkb.json'
import bgs from './json/bgs.json'
import ygs from './json/ygs.json'
import ylwds from './json/ylwds.json'
import mb from './json/mb.json'
import dldys from './json/dldys.json'
import kqb from './json/kqb.json'
import sb from './json/sb.json'
import sjs from './json/sjs.json'
import mdls from './json/mdls.json'
import qb from './json/qb.json'
import kyb from './json/kyb.json'
import klb from './json/klb.json'
export default {mgs,rkb,bgs,ygs,ylwds,mb,dldys,kqb,sb,sjs,mdls,qb,kyb,klb,mianDian}