<template>
  <div class="line_Wrap">
    <h2>
      <i>{{time}}{{name}}环比/同比</i>
    </h2>
    <div ref="myChart" :style="{'width':width,'height':height,'margin':'0 auto'}"></div>  
  </div>  
</template>

<script>
export default {
  name:'LineChart',
  props:{
    nationid: {
      type: Number,
      default: 2 // 国家
    },
    width:{
      type:String,
      default:'100%'
    },
    height:{
      type:String,
      default:'180px'
    },
    index:{
      type:[String,Number],
      default:0
    }
  },
  data() {
    return {
      myChart:'',
      xData:[],  // x轴省份
      seriesData1:[],  // 同比
      seriesData2:[],  // 环比
      chartData:{},  // 数据来源
      time:'',
      name:''
    }
  },
  watch:{
    chartData(newValue){
      this.chartData=newValue
      this.name=this.chartData[0].name
      this.formatData(this.chartData[0].areas_data)
      this.initChart()
    },
    index(newValue){
      this.name=this.chartData[newValue].name
      this.formatData(this.chartData[newValue].areas_data)
      this.initChart()
    }
  },
  created(){
    this.getNationData()
    // this.$nextTick(() => {
    //   //console.log('this.chartData',this.chartData)
    //   this.formatData(this.chartData.areas_data)
    //   this.initChart()
    // });
  },
  methods:{
    async getNationData(){
      let {data,terms_name} = await this.$reqApi.getDataIndex({
        nationid: this.nationid
      })
      this.chartData=data
      this.time=terms_name
    },
    formatData(obj){
      this.xData=[]
      this.seriesData1=[]
      this.seriesData2=[]
      for(let key in obj){        
        this.xData.push( this.$utils.filterStr(obj[key].name,) )
        this.seriesData1.push(obj[key].years_rate)
        this.seriesData2.push(obj[key].chain_rate)
      }      
    },
    initChart() {  // 画图表
      this.myChart = this.$echarts.init(this.$refs.myChart)  // 基于准备好的dom，初始化echarts实例
      this.myChart.clear()
      var options={
          legend: { data:[{name:'环比', icon:'rect'},{name:'同比',icon:'rect'}] , top:20,left:200,itemHeight:2,itemWidth:10,textStyle:{color : '#fff',fontSize:10} },
          tooltip : {trigger: 'axis',
            formatter: function (params) {  // 鼠标经过     
              return params[0].name+':同比'+params[0].value +'<br/>'+params[1].name+':环比'+params[1].value;
            }
          },
          xAxis: { //X轴
            axisLine: { show: false },  // 隐藏x轴横线
            splitLine: { show: false },  // 去除分隔线
            axisLabel:{ color: '#fff', fontSize:10, interval:0, rotate:60 }, // 坐标轴刻度标签的相关设置
            data: this.xData
          },
          yAxis: { // Y轴
            axisLine: { show: false },  // 隐藏Y轴横线
            splitLine: { show: false },  // 去除分隔线
            axisTick: { show: false },  // 去除刻度线
            axisLabel:{ show: true,  color: '#55D7FD', fontSize:10},
            splitNumber:3
          }, 
          // 数据
          series:[
            {
              name:'同比',
              type:'line',
              itemStyle: { color: "#2C54FF"}, // 折线点的颜色
              lineStyle: { color: "#2C54FF", shadowColor: '#516EFF', shadowBlur: 10 }, // 折线的颜色
              data: this.seriesData1
            },
            {
              name:'环比',
              type:'line',
              itemStyle: { color: "#00FFB4"}, // 折线点的颜色
              lineStyle: { color: "#00FFB4", shadowColor: '#51FFC4', shadowBlur: 10 }, // 折线的颜色
              data: this.seriesData2
            }
          ]
      }
      this.myChart.setOption(options) 
    }
  }
};
</script>

<style lang="less" scoped>
  .line_Wrap{ width: 370px;height: 226px; background: url('../../assets/img/homeRightBg.png') no-repeat; background-size: 100% 100%;
    h2{display: flex; align-items: center; justify-content: space-between; font-size: 16px; padding:20px 20px 0;
      i:first-child{color: #fff; font-weight: bold;
        &:before{content: ''; display: inline-block;width: 8px;height: 8px;border: 2px solid #55fdfd;border-radius: 50%;margin-right: 4px;}
      }
    }
  }  
</style>
