<template>
  <div class="bar_Wrap">
    <h2>
      <i>{{this.$utils.filterStr(areaName)}}{{chartData.name}}指数对比</i>
    </h2>
    <div ref="myChart" :style="{'width':width,'height':height,'margin':'0 auto','padding-left':'10px'}"></div>  
  </div>  
</template>

<script>
export default {
  name:'BarChart',
  props:{
    width:{
      type:String,
      default:'100%'
    },
    height:{
      type:String,
      default:'180px'
    },
    chartData:Object,   // 数据来源
    areaName:String,   // 地区
  },
  data() {
    return {
      myChart:'',
      xData:[],
      seriesData:[]
    }   
  },
  watch:{
    chartData(newValue){
      this.chartData=newValue
      this.formatData(this.chartData.terms_data)
      this.initChart()
    }
  },
  created(){
    // this.$nextTick(() => {
    //   this.initChart()
    // });
  },
  methods:{
    formatData(obj){
      this.xData=[]
      this.seriesData=[]
      for(let key in obj){
        this.xData.push( this.$utils.formatDate(key,'{0}年{1}月') )
        this.seriesData.push(obj[key].base_index)
      }      
    },
    initChart() {  // 画图表
      this.myChart = this.$echarts.init(this.$refs.myChart)  // 基于准备好的dom，初始化echarts实例
      this.myChart.clear()
      var options={
          legend: { data:[{name:'环比', icon:'rect'},{name:'同比',icon:'rect'}] , left:200,itemHeight:2,itemWidth:10,textStyle:{color : '#fff',fontSize:10} },
          tooltip : {trigger: 'axis',
            formatter: function (params) {  // 鼠标经过 
              return params[0].name+':' + Number(params[0].value)
            }
          },
          xAxis: { //X轴
            axisLine: { show: false },  // 隐藏x轴横线
            splitLine: { show: false },  // 去除分隔线
            axisTick: { show: false },  // 去除刻度线
            axisLabel:{ color: '#fff', fontSize:10, interval:0, rotate:60 }, // 坐标轴刻度标签的相关设置
            data: this.xData
          },
          yAxis: { // Y轴
            axisLine: { show: false },
            splitLine: { show: false },
            axisTick: { show: false },
            axisLabel:{ show: true,  color: '#55D7FD', fontSize:10},
            splitNumber:3
          }, 
          // 数据
          series:[
            {
              type:'bar',
              itemStyle: { color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0,color: '#38effd'}, {offset: 1,color: '#005fe2'}] )
              }, // 柱状条颜色
              data:this.seriesData,
              barMaxWidth:14  // 柱条的宽度
            },
          ]
      }
      this.myChart.setOption(options) 
    }
  }
};
</script>

<style lang="less" scoped>
  .bar_Wrap{ width: 370px;height: 236px; background: url('../../assets/img/homeRightBg.png') no-repeat; background-size: 100% 100%;
    h2{display: flex; align-items: center; justify-content: space-between; font-size: 16px; padding:20px 20px 0;
      i:first-child{color: #fff; font-weight: bold;
        &:before{content: ''; display: inline-block;width: 8px;height: 8px;border: 2px solid #55fdfd;border-radius: 50%;margin-right: 4px;}
      }
    }
  }  
</style>
