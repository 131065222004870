<template>
  <div class="map_Wrap">
    <p class="go_back" @click="goBack" v-if="isShow">返回</p>
    <div ref="myChart" :style="{'width':width,'height':height,'margin':'0 auto'}"></div>
  </div>  
</template>

<script>
// 缅甸数据集合
import MianDiann from './MianDiann'
// 老挝
import Laowo from './Laowo'

export default {
  name:'PieChart',
  props:{
    // 展示区域
    are: {
      type:String,
      default:'miandian'
    },
    width:{
      type:String,
      default:'440px'
    },
    height:{
      type:String,
      default:'620px'
    },
    chartData:Object,   // 数据来源
  },
  data() {
    return {
      myChart:'',
      upsymbol:require('@/assets/img/mapUp.png'),
      downsymbol:require('@/assets/img/mapDown.png'),
      cpsymbol:require('@/assets/img/mapFlat.png'),
      mainMap: null, // 大地图数据（次值不会被改变）
      areaMap: null, // 当前大地图数据
      markPointData:[],
      isShow:false,
      isClick:false,
      map: null,
      options:{}
    }   
  },
  watch:{
    chartData(newValue){
      this.chartData=newValue
      this.mapReset()
      if(this.isClick){  // 子级地图修改配置项
        this.options.series[0].label.fontSize=22
        this.options.series[0].itemStyle.areaColor='rgba(96,100,253,.2)'
        this.options.series[0].emphasis.label.fontSize=22
        this.myChart.setOption(this.options)
      }
    }
  },
  created(){
    // this.$nextTick(() => {
    //   console.log('this.chartData',this.chartData)
    //   this.initChart()
    // });
  },
  mounted(){
    // 根据当前显示内容动态加载地图
    if (this.are === 'miandian') {
      // 缅甸地图
      this.areaMap = MianDiann.mianDian
      this.mainMap = MianDiann.mianDian
      // 小地图数据映射关系
      this.map = {'349':MianDiann.mgs,'548':MianDiann.rkb,'344':MianDiann.bgs,'302':MianDiann.ygs,'528':MianDiann.ylwds,'545':MianDiann.mb,'537':MianDiann.dldys,'307':MianDiann.kqb,'312':MianDiann.sb,'335':MianDiann.sjs,'327':MianDiann.mdls,'541':MianDiann.qb,'360':MianDiann.kyb,'355':MianDiann.klb}
    } else if (this.are === 'laowo') {
      // 缅甸地图
      this.areaMap = Laowo.main
      this.mainMap = Laowo.main
      // 尝试从大地图中计算映射出每一个小地图的值
      let map = {}
      const featureArr = Laowo.main.features
      featureArr.forEach( feature => {
        map[feature.properties.id.toString()] = Laowo[`map${feature.properties.id.toString()}`]
      })
      this.map = map
    }
    console.log('地区选择', this.map)
    window.addEventListener("resize", this.mapResize)
  },
  beforeDestroy(){
    window.removeEventListener("resize", this.mapResize)
  },
  methods:{
    mapReset(){
      this.formatData(this.chartData.areas_data)
      this.initChart()
    },
    mapResize(){
      this.myChart.resize()
    },
    formatData(Obj){
      this.markPointData=[]
      const featureArr = this.areaMap.features
      featureArr.forEach( feature => {
        for(let key in Obj){
          let symbol=''
          if(feature.properties.id == key){
            let num = Obj[key].chain_rate
            if(num){
              if(num>0) symbol=this.upsymbol
              else if(num<0) symbol=this.downsymbol
              else symbol=this.cpsymbol
            }
          }
          this.markPointData.push({
            name: feature.properties.name,
            coord: feature.properties.cp,
            symbol:'image://'+symbol
          })
        }
      })
    },
    initChart() {  // 画图表
      this.$echarts.registerMap('zidinyi', this.areaMap);
      this.myChart = this.$echarts.init(this.$refs.myChart)  // 基于准备好的dom，初始化echarts实例
      this.myChart.off('click')
      // 绘制地图
      this.options={
          series: [
            {
              type: 'map',
              top:20,
              left:0,
              right:20,
              bottom:30,
              map: 'zidinyi',
              // aspectScale:1.1,
              // boundingCoords: [
              //     // 定位左上角经纬度
              //     [-314, 86],
              //     // 定位右下角经纬度
              //     [-141, -25]
              // ],
              label: {
                  show: true,//默认是否显示省份名称
                  color:'#fff',//修改字体颜色
                  fontSize:12,
              },
              itemStyle: {
                  areaColor:'rgba(0,0,0,0)',//设置背景颜色
                  borderWidth:2,
                  borderColor:'#43bcff',//设置边框颜色
              },
              emphasis: {//鼠标移入动态的时候显示的默认样式
                  label: {
                      show: true,//选中状态是否显示省份名称
                      color:'#ff0',//修改字体颜色
                      fontSize:16
                  },
                  itemStyle:{
                      areaColor:'rgba(96,100,253,.5)'
                  }
              },
              markPoint:{  //图表标注
                data:this.markPointData,
                symbolSize:20,
                silent:true,
                symbolOffset:['0%', '-120%']
              }
            }
          ]
      }
      this.myChart.setOption(this.options) 
      this.myChart.resize()
      this.myChart.on('click', (params)=>{  // 地图击事件内容
        if(this.isClick) {
          this.$router.push('/ReportData')
          return
        }
        const areaName = params.name
        this.areaMap.features.forEach((item)=>{
          if(item.properties.name == areaName){
            this.$emit('handleClickMap',item.properties.id,()=>{
              this.isShow=this.isClick=true
              // 将当前显示的地图替换
              this.areaMap=this.map[item.properties.id]
              this.mapReset()
              // 子级地图修改配置项
              this.options.series[0].label.fontSize=22
              this.options.series[0].itemStyle.areaColor='rgba(96,100,253,.2)'
              this.options.series[0].emphasis.label.fontSize=22
              this.myChart.setOption(this.options)
            })            
          }
        })
      });
    },
    goBack(){
      this.isShow = this.isClick = false
      this.areaMap = this.mainMap
      this.$emit("resetMap")
    }
  }
};
</script>

<style lang="less" scoped>
  .map_Wrap{position: relative; padding-top:30px ;
    .go_back{cursor:pointer;color:#55c0fd;line-height:30px;padding-left: 60px;font-weight: normal;position: absolute; top:0; left: 0;}
  }  
</style>
