import main from "./json/main.json";

import map363 from "./json/map363.json";
import map373 from "./json/map373.json";
import map384 from "./json/map384.json";
import map390 from "./json/map390.json";
import map396 from "./json/map396.json";
import map404 from "./json/map404.json";
import map416 from "./json/map416.json";
import map429 from "./json/map429.json";
import map437 from "./json/map437.json";
import map446 from "./json/map446.json";
import map452 from "./json/map452.json";
import map460 from "./json/map460.json";
import map471 from "./json/map471.json";
import map481 from "./json/map481.json";
import map492 from "./json/map492.json";
import map501 from "./json/map501.json";
import map517 from "./json/map517.json";

export default {
  main,
  map363,
  map373,
  map384,
  map390,
  map396,
  map404,
  map416,
  map429,
  map437,
  map446,
  map452,
  map460,
  map471,
  map481,
  map492,
  map501,
  map517,
};
